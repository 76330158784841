import { MrAdvancedSearchLayoutDS } from '@net7/boilerplate-muruca';
import { InputSelectData } from '@net7/components';
import { Observable, of } from 'rxjs';

export class TtAdvancedSearchLayoutDS extends MrAdvancedSearchLayoutDS {
  onInit(payload) {
    super.onInit(payload);

    this.getTypeOptions$().subscribe((data: InputSelectData) => {
      const typeDS = this.form.getInput('type');
      typeDS.update(data);
    });
  }

  getTypeOptions$(): Observable<InputSelectData> {
    return of({
      id: 'select-1',
      label: 'Paesi',
      payload: 'select-1-payload',
      options: [{
        value: 'italia',
        label: 'advancedsearch#test_select_italia',
        disabled: false
      }, {
        value: 'germania',
        label: 'advancedsearch#test_select_germania',
        disabled: false
      }, {
        value: 'francia',
        label: 'advancedsearch#test_select_francia',
        disabled: true
      }]
    });
  }
}
