import tabsConfig from './tabs.config';
import homeBaseConfig from './home-base.config';
import searchWorksConfig from './search-works.config';
import resourceWorkDatosBibliograficosConfig from './resource-work-datos-bibliograficos.config';
import resourceWorkDatosCodicologicosConfig from './resource-work-datos-codicologicos.config';
import resourceWorkProcesoComposicionConfig from './resource-work-proceso-composicion.config';
import resourceWorkBibliografiaCitadaConfig from './resource-work-bibliografia-citada.config';
import searchBibliografia from './search-bibliography.config';
import advancedSearchConfig from './advanced-search.config';
import advancedResultsConfig from './advanced-results.config';

export default {
  tabs: tabsConfig,
  'home-base': homeBaseConfig,
  'search-works': searchWorksConfig,
  'search-bibliografia': searchBibliografia,
  'resource-work-datos-bibliograficos': resourceWorkDatosBibliograficosConfig,
  'resource-work-datos-codicologicos': resourceWorkDatosCodicologicosConfig,
  'resource-work-proceso-composicion': resourceWorkProcesoComposicionConfig,
  'resource-work-bibliografia-citada': resourceWorkBibliografiaCitadaConfig,
  'advanced-search': advancedSearchConfig,
  'advanced-results': advancedResultsConfig
};
