import { Routes } from '@angular/router';

import {
  // COMMON
  Page404LayoutComponent,

} from '@net7/boilerplate-common';

import {
  // MURUCA
  MrHomeLayoutComponent,
  MrSearchLayoutComponent,
  MrStaticLayoutComponent,
  MrResourceLayoutComponent,
  MrAdvancedSearchLayoutComponent,
  MrAdvancedResultsLayoutComponent,
  // OTHER
  DynamicPathGuard

} from '@net7/boilerplate-muruca';

const NOT_FOUND_PATH = 'page-404';

export const APP_ROUTES: Routes = [
  {
    path: 'inicio',
    redirectTo: '/',
    pathMatch: 'full'
  },
  { path: '', component: MrHomeLayoutComponent, data: { configId: 'home-base' } },
  { path: 'obras', component: MrSearchLayoutComponent, data: { configId: 'search-works' } },
  {
    path: 'bibliografia',
    component: MrSearchLayoutComponent,
    data: { configId: 'search-bibliografia' }
  },
  {
    path: 'obra/:id/:slug',
    redirectTo: '/obra/:id/:slug/datos-bibliograficos',
    pathMatch: 'full'
  },
  {
    path: 'obra/:id/:slug/datos-bibliograficos',
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-work-datos-bibliograficos' }
  },
  {
    path: 'obra/:id/:slug/datos-codicologicos',
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-work-datos-codicologicos' }
  },
  {
    path: 'obra/:id/:slug/proceso-composicion',
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-work-proceso-composicion' }
  },
  {
    path: 'obra/:id/:slug/bibliografia-citada',
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-work-bibliografia-citada' }
  },
  { path: 'busqueda-avanzada', component: MrAdvancedSearchLayoutComponent, data: { configId: 'advanced-search' } },
  { path: 'resultados', component: MrAdvancedResultsLayoutComponent, data: { configId: 'advanced-results' } },
  { path: NOT_FOUND_PATH, component: Page404LayoutComponent },
  {
    path: '**',
    component: MrStaticLayoutComponent,
    canActivate: [DynamicPathGuard],
    data: {
      notFoundPath: NOT_FOUND_PATH
    }
  }
];
