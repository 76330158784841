export default {
  searchId: 'advanced_search',
  title: 'Búsqueda avanzada',
  resourcePath: '/work',
  totalResultsText: 'search#works_total',
  grid: 1,
  filters: {
    labels: {
      author: 'advancedsearch#label_author',
      query: 'advancedsearch#label_title',
      'free-search': 'advancedsearch#label_free_search',
      'res_type': 'advancedsearch#type_label',
      'year': 'advancedsearch#label_date',
      'date-range': 'advancedsearch#label_date_range',
      'collocation': 'advancedsearch#label_collocation',
      'source': 'advancedsearch#label_source',
      'note': 'advancedsearch#label_note',
      'troupe': 'advancedsearch#troupe_label',
      'actors': 'advancedsearch#actors_label',
      'old-editions': 'advancedsearch#old_editions_label',
      'modern-editions': 'advancedsearch#modern_editions_label',
      'other-manuscripts': 'advancedsearch#other_manuscripts_label',
      'bibliographical_note_title': 'advancedsearch#label_note',
      'censorship': 'advancedsearch#value_censorship_exists_label',
      'censor': 'advancedsearch#label_censor',
      'censorship_date': 'advancedsearch#label_censorship_date',
      'censorship_note': 'advancedsearch#label_censorship_note',
      'phisical_description': 'advancedsearch#label_phisical_description',
      'cover_page': 'advancedsearch#cover_page_label',
      'dramatis_personae': 'advancedsearch#dramatis_personae_label',
      'ph_desc_signature': 'advancedsearch#ph_desc_signature_label',
      'ph_desc_date': 'advancedsearch#ph_desc_date_label',
      'other_hand': 'advancedsearch#other_hand_label',
      'analytics_description': 'advancedsearch#label_analytics_description',
      'manuscript_characteristics': 'advancedsearch#label_manuscript_characteristics',
      'external_interventions': 'advancedsearch#external_interventions_exists_label',
      'external_intervention_text': 'advancedsearch#external_intervention_description',
      'external_intervention_resp': 'advancedsearch#external_intervention_resp_label',
      'is_draft': 'advancedsearch#is_draft_label',
      'has_revisions': 'advancedsearch#revisions_exists_label',
      'revisions': 'advancedsearch#label_revisions',
      'is_revision_marginalia': 'advancedsearch#is_revision_marginalia_label',
      'is_deleted_fragments': 'advancedsearch#is_deleted_fragments_label',
      'deleted_fragments': 'advancedsearch#label_deleted_fragments',
      'first_writing': 'advancedsearch#label_first_writing',
      censors: 'advancedsearch#label_censors',
      signature: 'advancedsearch#label_signature'
    }
  },
  sort: {
    label: 'search#sort_title',
    options: [
      {
        value: '_score',
        label: 'search#sort_score',
        selected: false,
        disabled: true
      },
      {
        value: 'sort_ASC',
        label: 'search#sort_asc',
        selected: true
      },
      {
        value: 'sort_DESC',
        label: 'search#sort_desc',
        selected: false
      }
    ]
  },
  pagination: {
    selectLabel: 'search#results_number',
    limit: 5,
    options: [
      12,
      24,
      48
    ]
  },
  itemPreview: {
    // classes: 'is-vertical'
  },
  fallback: {
    text: 'search#fallback_text',
    button: 'search#fallback_button'
  },
  ko: {
    text: 'search#ko_text',
    button: 'search#ko_button'
  }
};
