export default {
  logo: {
    image: '/assets/logo_auteso_new.png',
    anchor: {
      href: '/'
    }
  },
  nav: {
    items: []
  }
};
