export default {
  obra: [
    {
      id: 'datos-bibliograficos',
      label: 'tabs#work_bibliographic_data'
    },
    {
      id: 'datos-codicologicos',
      label: 'tabs#work_codicological_data'
    },
    {
      id: 'proceso-composicion',
      label: 'tabs#work_composition_process'
    },
    {
      id: 'bibliografia-citada',
      label: 'tabs#work_cited_bibliography'
    }
  ]
};
