export default {
  'global#home': 'Inicio',
  'global#works': 'Obras',
  'global#date_human': 'Do MMMM YYYY',
  'global#layout_error_title': 'Spiacenti, si è verificato un errore',
  'global#layout_error_description': 'Prova a ricaricare la pagina tra qualche istante e se il problema persiste contatta l\'amministratore della piattaforma.',
  'readmore#label': 'Mostrar todo',
  'search#title_works': 'Obras',
  'search#title_bibliographies': 'Bibliografía',
  'search#maps_total': '<strong>{ total }</strong> Mapas',
  'search#maps_total_1': '<strong>{ total }</strong> Mapa',
  'search#maps_total_0': 'Sin mapa',
  'search#bibliographies_total': '<strong>{ total }</strong> Bibliografías',
  'search#bibliographies_total_1': '<strong>{ total }</strong> Bibliografía',
  'search#bibliographies_total_0': 'Sin bibliografía',
  'search#works_total': '<strong>{ total }</strong> Obras',
  'search#works_total_1': '<strong>{ total }</strong> Obra',
  'search#works_total_0': 'Sin obra',
  'search#placeholder_query': 'Buscar en titulos',
  'search#placeholder_types': 'Buscar en tipologías',
  'search#placeholder_authors': 'Buscar en autores',
  'search#header_types': 'Tipologías',
  'search#header_collocations': 'Localizaciones',
  'search#header_authors': 'Autores',
  'search#header_dates': 'Años',
  'search#title': 'Obras',
  'search#facets_title': 'Filtro de resultados',
  'search#filters_title': 'Filtros activos:',
  'search#sort_title': 'Ordenar',
  'search#sort_score': 'Orden por relevancia',
  'search#sort_asc': 'Orden alfabético (A→Z)',
  'search#sort_desc': 'Orden alfabético (Z→A)',
  'search#fallback_text': 'No se han encontrado registros con estos parámetros de búsqueda.',
  'search#fallback_button': 'Cancelar la búsqueda',
  'search#ko_text': 'Oops, abbiamo riscontrato un errore nella ricerca.',
  'search#ko_button': 'Cancelar la búsqueda',
  'search#results_number': 'Número de resultados',
  'resource#authors': 'de',
  'resource#date': 'publicado en',
  'resource#time_to_read': 'lectura de',
  'resource#metadata': 'metadatos',
  'resource#metadata_size': 'Dimensioni',
  'resource#page_bibliographic_data': 'Obra > Datos bibliográficos',
  'resource#page_codicological_data': 'Obra > Datos codicológicos',
  'resource#page_composition_process': 'Obra > Proceso de composición',
  'resource#page_cited_bibliography': 'Obra > Bibliografía citada',
  'tabs#work_bibliographic_data': 'Datos bibliográficos',
  'tabs#work_codicological_data': 'Datos codicológicos',
  'tabs#work_composition_process': 'Proceso de composición',
  'tabs#work_cited_bibliography': 'Bibliografía citada',
  'advancedsearch#title': 'Búsqueda avanzada',
  'advancedsearch#label_title': 'Título',
  'advancedsearch#label_author': 'Autor',
  'advancedsearch#auto_sacramental': 'auto Sacramental',
  'advancedsearch#comedia': 'comedia',
  'advancedsearch#comedia_fragmento': 'comedia (fragmento)',
  'advancedsearch#dedicatoria': 'dedicatoria',
  'advancedsearch#label_signature': 'Signatura',
  'advancedsearch#label_date': 'Fecha de composición',
  'advancedsearch#value_censorship_exists_label': 'Censuras y licencias',
  'advancedsearch#value_censors_exists': 'contiene',
  'advancedsearch#value_censors_not_exists': 'no contiene',
  'advancedsearch#value_censors_exists_label': 'La obra contiene censuras',
  'advancedsearch#reset': 'Reset',
  'advancedsearch#submit': 'Buscar',
  'advancedsearch#bibliographical_data': 'Datos bibliográficos',
  'advancedsearch#type_label': 'Tipología',
  'advancedsearch#label_collocation': 'Localización',
  'advancedsearch#label_source': 'Procedencia',
  'advancedsearch#label_note': 'Nota bibliográfica',
  'advancedsearch#label_censor': 'Censor',
  'advancedsearch#label_censors': 'Censuras y licencias',
  'advancedsearch#label_censorship_date': 'Fecha Censura',
  'advancedsearch#label_censorship_note': 'Notas censura',
  'advancedsearch#bibliographical_note_title': 'Notas bibliográficas',
  'advancedsearch#troupe_label': 'Compañía teatral',
  'advancedsearch#actors_label': 'Actores',
  'advancedsearch#old_editions_label': 'Ediciones antiguas',
  'advancedsearch#modern_editions_label': 'Ediciones modernas',
  'advancedsearch#other_manuscripts_label': 'Otros manuscritos',
  'advancedsearch#no_consists': 'no constan',
  'advancedsearch#consists': 'constan',
  'advancedsearch#certain': 'individuada',
  'advancedsearch#probable': 'nombre probable',
  'advancedsearch#unknown': 'desconocido',
  'advancedsearch#value_exists': 'Sí',
  'advancedsearch#value_not_exists': 'No',
  'advancedsearch#highlights_title': 'Resultados',
  'advancedsearch#codicological_data': 'Datos codicológicos',
  'advancedsearch#codicological_data_title': 'Datos codicológicos',
  'advancedsearch#label_phisical_description': 'Descripción externa',
  'advancedsearch#cover_page_label': 'Portada',
  'advancedsearch#dramatis_personae_label': 'Dramatis personae',
  'advancedsearch#ph_desc_signature_label': 'Firma',
  'advancedsearch#ph_desc_date_label': 'Fecha',
  'advancedsearch#other_hand_label': 'Otras grafías',
  'advancedsearch#label_analytics_description': 'Descripción analítica',
  'advancedsearch#label_manuscript_characteristics': 'Características del manuscrito',
  'advancedsearch#writing_process': 'Proceso de composición',
  'advancedsearch#writing_process_title': 'Proceso de composición',
  'advancedsearch#writing_process_autograph_title': 'Redacción autógrafa',
  'advancedsearch#is_draft_label': 'Borrador',
  'advancedsearch#label_revisions': 'Revisión',
  'advancedsearch#revisions_exists_label': 'Revisión',
  'advancedsearch#label_first_writing': 'Primera redacción',
  'advancedsearch#is_revision_marginalia_label': 'Fragmentos añadidos en los márgenes',
  'advancedsearch#is_deleted_fragments_label': 'Fragmentos tachados o enjaulados',
  'advancedsearch#label_deleted_fragments': 'Fragmentos tachados o enjaulados',
  'advancedsearch#external_interventions_title': ' Intervenciones ajenas',
  'advancedsearch#external_interventions_exists_label': ' Intervenciones ajenas',
  'advancedsearch#external_intervention_resp_label': 'Responsable de la intervenciones ajenas',
  'advancedsearch#resp_troupe': 'companía teatral',
  'advancedsearch#resp_censor': 'censor',
  'advancedsearch#resp_other': 'otros',
  'advancedsearch#external_intervention_description': 'Intervenciones ajenas',
  'advancedsearch#general_data': 'Busqueda general',
  'advancedsearch#label_free_search': 'Todos los campos',
  'advancedsearch#fecha_undefined': 'no definible',
  'advancedsearch#fecha_before1590': 'Antes de 1590',
  'advancedsearch#fecha_after1661': 'Después de 1661',
  'advancedsearch#label_date_range': 'Intervalo'
};
